import dayjs from "dayjs";

export const filterText = (dataValue, paramValues) =>
  paramValues
    ? dataValue
        ?.toString()
        ?.toLowerCase()
        .indexOf(paramValues?.toString()?.toLowerCase()) > -1
    : true;

export const filterDateTimeBetween = (dataValue, startTime, endTime) => {
  return startTime && endTime
    ? dayjs(dataValue).tz("Asia/Kuwait").format("YYYY-MM-DD HH:mm") >=
        dayjs(startTime).subtract(1, "minutes").format("YYYY-MM-DD HH:mm") &&
        dayjs(dataValue).tz("Asia/Kuwait").format("YYYY-MM-DD HH:mm") <=
          dayjs(endTime).format("YYYY-MM-DD HH:mm")
    : true;
};

export const filterDateTime = (dataValue, formValue) => {
  return formValue
    ? dayjs(dataValue).tz("Asia/Kuwait").format("YYYY-MM-DD HH:mm") ===
        dayjs(formValue).format("YYYY-MM-DD HH:mm")
    : true;
};
