import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // replace with your API endpoint
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || null;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.baseURL = process.env.REACT_APP_API_URL;

  return config;
});

// Add a response interceptor to handle 403 errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403) {
      alert("Forbidden");
      // handle 403 error, such as redirect to login page
      localStorage.removeItem("token");
    }

    return Promise.reject(error);
  }
);

export default api;
